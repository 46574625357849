import React from 'react';
import { graphql } from 'gatsby';
import { HelmetDatoCms } from 'gatsby-source-datocms';
import Layout from '../../components/Layout';
import Banner from '../../components/Banner';
import Cards from '../../components/Cards';

const CaseStudiesPage = ({
  data: {
    datoCmsBannerImage: { caseStudiesBannerImage },
    allDatoCmsCaseStudy: { nodes },
  },
}) => (
  <Layout>
    <HelmetDatoCms title="Case Studies | Barcanet" />
    <main>
      <Banner heading="Case Studies" image={caseStudiesBannerImage} />
      <Cards items={nodes} baseSlug="/resources/case-studies/" />
    </main>
  </Layout>
);

export const CaseStudiesPageQuery = graphql`
  query CaseStudiesPageQuery {
    datoCmsBannerImage {
      caseStudiesBannerImage {
        ...BannerImageFragment
      }
    }
    allDatoCmsCaseStudy(sort: { fields: position }) {
      nodes {
        id
        title
        slug
        featuredImage {
          fluid(
            maxHeight: 225
            maxWidth: 400
            imgixParams: { auto: "compress", fit: "crop", h: "225", w: "400" }
          ) {
            ...GatsbyDatoCmsFluid
          }
          alt
        }
      }
    }
  }
`;

export default CaseStudiesPage;
